@charset "UTF-8";
html,
body {
  height: 100%;
  min-height: 100%;
}

/* Font */
/* Colors */
/* Gradients */
/* Buttons height */
.background-gray {
  background-color: #f6f6f6;
}

.header {
  align-items: center !important;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px rgba(35, 46, 36, 0.08);
  display: flex !important;
  height: 64px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  animation: appear-up 0.5s;
  transition-duration: 5s ease;
  z-index: 1000;
}

.header-content {
  align-items: center;
  display: flex;
  padding-left: 50px;
}

.header-close-session {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: transparent;
  animation: appear-down 0.5s;
  transition-duration: 5s ease;
  z-index: 1;
}

.footer-left-content {
  background: none;
  padding-bottom: 18px;
  padding-left: 30px;
  padding-top: 2px;
}

.footer-left-content::after {
  content: "© Banco Popular | 2022";
}

.footer-right-content {
  background-image: url("../images/templates/aval.svg");
  background-repeat: no-repeat;
  background-position-x: right;
  background-size: contain;
  height: 23px;
  margin-left: -30px;
  padding-bottom: 18px;
  padding-right: 20px;
}

.footer-right-content::after {
  content: "";
}

.grecaptcha-badge {
  height: 1px !important;
  width: 1px !important;
}

@media (max-width: 485px) {
  .header-content {
    padding-left: 30px !important;
  }
}
@media (max-width: 1200px) {
  .footer-left-content {
    background-image: url("../images/templates/aval.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 23px;
    padding-bottom: 10px;
    padding-left: 0;
    padding-top: 0;
  }
  .footer-left-content::after {
    content: "";
  }
  .footer-right-content {
    background: none;
    margin-left: 0;
    padding-bottom: 10px;
    text-align: center;
  }
  .footer-right-content::after {
    content: "© Banco Popular | 2022";
    font-size: 0.94em;
  }
}
@media (max-width: 1024px) {
  .footer {
    background: #ffffff;
    padding: 10px;
  }
}
@media all and (max-width: 720px) and (max-height: 657px), (max-height: 450px) {
  .header-content {
    height: 26px;
    margin-top: 15px;
  }
  .header-close-session {
    margin-top: 5px;
  }
}
@media (max-height: 576px) {
  .footer {
    display: none;
  }
}
@media (max-height: 376px) {
  .header {
    display: none;
  }
}
/* General Animation */
@keyframes appear-up {
  0% {
    transform: translateY(-600px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes disappear-up {
  to {
    transform: translateY(-253px);
    visibility: hidden;
  }
}
@keyframes appear-down {
  0% {
    transform: translateY(600px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes appear-left {
  0% {
    transform: translateX(-600px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes disappear-left {
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: 600px;
    visibility: hidden;
  }
}
@keyframes appear-right {
  0% {
    transform: translateX(600px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes disappear-right {
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: -600px;
    visibility: hidden;
  }
}
@keyframes show-scale {
  0% {
    transform: scale(0.4);
  }
  40% {
    transform: scale(1.2);
  }
  65% {
    transform: scale(0.9);
  }
  80% {
    transform: scale(1.05);
  }
  90% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
    visibility: visible;
  }
}
@keyframes hidden-scale {
  to {
    transform: scale(0);
    visibility: hidden;
  }
}
@keyframes show-boom {
  0% {
    transform: translateY(-600px) scale(0.2);
  }
  90% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(1);
    visibility: visible;
  }
}
@keyframes hidden-boom {
  to {
    transform: scaleX(0);
    visibility: hidden;
  }
}
@keyframes show-diagonal-right {
  from {
    transform: translateX(798px) translateY(630px) scale(0.1);
  }
  to {
    transform: scale(1);
    visibility: visible;
  }
}
@keyframes hidden-diagonal-right {
  to {
    top: 630px;
    transform: scale(0.1);
    visibility: hidden;
  }
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-Regular.ttf");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-Medium.ttf");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-SemiBold.ttf");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/inter/Inter-Bold.ttf");
  font-style: normal;
  font-weight: 700;
}
.text-minimal-small {
  font-size: 0.6em;
}

.text-special-small {
  font-size: 0.7em;
}

.text-maximum-small {
  font-size: 0.75em;
}

.text-extra-small {
  font-size: 0.85em;
}

.text-small {
  font-size: 0.94em;
}

.text-medium {
  font-size: 1em;
}

.text-large {
  font-size: 1.12em;
}

.text-extra-large {
  font-size: 1.8em;
}

.text-orange {
  color: #ff7200;
}

.text-inherit {
  font-size: inherit;
}

.principal-Hero-regular {
  font-family: "Inter", sans-serif;
  font-size: 88px;
  font-weight: 400;
  line-height: 112px;
}

.principal-Hero-medium {
  font-family: "Inter", sans-serif;
  font-size: 88px;
  font-weight: 500;
  line-height: 112px;
}

.principal-Hero-semibold {
  font-family: "Inter", sans-serif;
  font-size: 88px;
  font-weight: 600;
  line-height: 112px;
}

.principal-Hero-bold {
  font-family: "Inter", sans-serif;
  font-size: 88px;
  font-weight: 700;
  line-height: 112px;
}

.principal-H1-regular {
  font-family: "Inter", sans-serif;
  font-size: 56px;
  font-weight: 400;
  line-height: 64px;
}

.principal-H1-medium {
  font-family: "Inter", sans-serif;
  font-size: 56px;
  font-weight: 500;
  line-height: 64px;
}

.principal-H1-semibold {
  font-family: "Inter", sans-serif;
  font-size: 56px;
  font-weight: 600;
  line-height: 64px;
}

.principal-H1-bold {
  font-family: "Inter", sans-serif;
  font-size: 56px;
  font-weight: 700;
  line-height: 64px;
}

.principal-H2-regular {
  font-family: "Inter", sans-serif;
  font-size: 48px;
  font-weight: 400;
  line-height: 56px;
}

.principal-H2-medium {
  font-family: "Inter", sans-serif;
  font-size: 48px;
  font-weight: 500;
  line-height: 56px;
}

.principal-H2-semibold {
  font-family: "Inter", sans-serif;
  font-size: 48px;
  font-weight: 600;
  line-height: 56px;
}

.principal-H2-bold {
  font-family: "Inter", sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
}

.principal-H3-regular {
  font-family: "Inter", sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
}

.principal-H3-medium {
  font-family: "Inter", sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
}

.principal-H3-semibold {
  font-family: "Inter", sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
}

.principal-H3-bold {
  font-family: "Inter", sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
}

.principal-H4-regular {
  font-family: "Inter", sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 36px;
}

.principal-H4-medium {
  font-family: "Inter", sans-serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
}

.principal-H4-semibold {
  font-family: "Inter", sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
}

.principal-H4-bold {
  font-family: "Inter", sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
}

.principal-H5-regular {
  font-family: "Inter", sans-serif;
  font-size: 21px;
  font-weight: 400;
  line-height: 28px;
}

.principal-H5-semibold {
  font-family: "Inter", sans-serif;
  font-size: 21px;
  font-weight: 600;
  line-height: 28px;
}

.principal-H5-bold {
  font-family: "Inter", sans-serif;
  font-size: 21px;
  font-weight: 700;
  line-height: 28px;
}

.principal-Subtitle1-regular {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.principal-Subtitle1-medium {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.principal-Subtitle1-semibold {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

.principal-Subtitle1-bold {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

.principal-Subtitle2-regular {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.principal-Subtitle2-medium {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.principal-Subtitle2-semibold {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.principal-Subtitle2-bold {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.principal-Action1-regular {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
}

.principal-Action1-medium {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
}

.principal-Action1-semibold {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
}

.principal-Action1-bold {
  font-family: "Inter", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
}

.principal-Action2-regular {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.principal-Action2-medium {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.principal-Action2-semibold {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

.principal-Action2-bold {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.principal-Action3-regular {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
}

.principal-Action3-medium {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
}

.principal-Action3-semibold {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 17px;
}

.principal-Action3-bold {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 17px;
}

.principal-Body1-regular {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.principal-Body1-medium {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.principal-Body1-semibold {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

.principal-Body1-bold {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
}

.principal-Body2-regular {
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
}

.principal-Body2-medium {
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 22px;
}

.principal-Body2-semibold {
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-weight: 600;
  line-height: 22px;
}

.principal-Body2-bold {
  font-family: "Inter", sans-serif;
  font-size: 13px;
  font-weight: 700;
  line-height: 22px;
}

.principal-Body3-regular {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.principal-Body3-medium {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}

.principal-Body3-semibold {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
}

.principal-Body3-bold {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
}

.principal-Caption-regular {
  font-family: "Inter", sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
}

.principal-Caption-medium {
  font-family: "Inter", sans-serif;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
}

.principal-Caption-semibold {
  font-family: "Inter", sans-serif;
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
}

.principal-Caption-bold {
  font-family: "Inter", sans-serif;
  font-size: 11px;
  font-weight: 700;
  line-height: 16px;
}

.principal-Overline-regular {
  font-family: "Inter", sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-transform: uppercase;
}

.principal-Overline-medium {
  font-family: "Inter", sans-serif;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  text-transform: uppercase;
}

.principal-Overline-semibold {
  font-family: "Inter", sans-serif;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  text-transform: uppercase;
}

.principal-Overline-bold {
  font-family: "Inter", sans-serif;
  font-size: 10px;
  font-weight: 700;
  line-height: 14px;
  text-transform: uppercase;
}

/* Style freshchat */
#custom_fc_button {
  background-color: transparent;
  position: fixed;
  bottom: 13px;
  right: 14px;
  height: 30px;
  width: 30px;
  z-index: 2000;
  visibility: hidden;
}

#imageicon {
  height: 30px;
  width: 30px;
}

@media (min-width: 810px) {
  #custom_fc_button {
    right: 17px;
    height: 50px;
    width: 50px;
  }
  #imageicon {
    height: 50px;
    width: 50px;
  }
}
@media (min-width: 1200px) {
  #custom_fc_button {
    bottom: 45px;
    right: 13px;
  }
}
/* Col zero */
@media (max-width: 1200px) {
  .col-md-0 {
    display: none !important;
  }
}
/* Height fixed */
.h-2-px {
  content: "";
  height: 2px !important;
}

.h-8-px {
  content: "";
  height: 8px !important;
}

.h-14-px {
  content: "";
  height: 14px !important;
}

.h-20-px {
  content: "";
  height: 20px !important;
}

.h-25-px {
  content: "";
  height: 25px !important;
}

.h-26-px {
  content: "";
  height: 26px !important;
}

.h-30-px {
  content: "";
  height: 30px !important;
}

.h-40-px {
  content: "";
  height: 40px !important;
}

.h-50-px {
  content: "";
  height: 50px !important;
}

.h-60-px {
  content: "";
  height: 60px !important;
}

.h-70-px {
  content: "";
  height: 70px !important;
}

.h-75-px {
  content: "";
  height: 75px !important;
}

.h-90-px {
  content: "";
  height: 90px !important;
}

.h-110-px {
  content: "";
  height: 110px !important;
}

.h-135-px {
  content: "";
  height: 135px !important;
}

.h-175-px {
  content: "";
  height: 175px !important;
}

.h-260-px {
  content: "";
  height: 260px !important;
}

/* Width fixed */
.w-132-px {
  width: 132px !important;
}

.w-191-px {
  width: 191px !important;
}

.w-205-px {
  width: 205px !important;
}

.w-211-px {
  width: 205px !important;
}

.w-289-px {
  width: 205px !important;
}

.w-400-px {
  width: 400px !important;
}

/* Width dynamic */
.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

/* Margin fixed */
.m-b-2-px {
  margin-bottom: 2px;
}

.m-b-3-px {
  margin-bottom: 3px;
}

.m-b-4-px {
  margin-bottom: 4px;
}

.m-b-5-px {
  margin-bottom: 5px;
}

.m-b-10-px {
  margin-bottom: 10px;
}

.m-l-1-px {
  margin-left: 1px !important;
}

.m-r-1-px {
  margin-right: 1px !important;
}

.m-r-20-px {
  margin-right: 20px !important;
}

.m-t-18-px {
  margin-top: 18px;
}

/* Padding fixed */
.p-b-10-px {
  padding-bottom: 10px;
}

.p-b-40-px {
  padding-bottom: 40px;
}

.p-b-97-px {
  padding-bottom: 97px;
}

.p-l-10-px {
  padding-left: 10px;
}

.p-l-15-px {
  padding-left: 15px;
}

.p-l-40-px {
  padding-left: 40px;
}

.p-r-10-px {
  padding-right: 10px;
}

.p-t-7-px-s-40-px {
  padding: 7px 40px;
}

.p-t-97-px {
  padding-top: 97px;
}

/* Padding dynamic */
.p-b-18 {
  padding-bottom: 18% !important;
}

.p-b-9 {
  padding-bottom: 9% !important;
}

@media (max-width: 576px) {
  .p-l-r-sm-10-px {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .p-b-sm-6 {
    padding-bottom: 6% !important;
  }
}
/* Others column */
@media (min-width: 576px) {
  .col-sm-2-5 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
/* Main */
#main {
  position: relative;
  width: 100%;
  height: 100%;
}

/* Borders */
.border-bottom-green {
  border-bottom: 1px solid #009a48;
}

.border-right-green {
  border-right: 1px solid #009a48;
}

/* Cursors */
.cursor-pointer {
  cursor: pointer;
}

/* Input */
input {
  border-radius: 4px;
  color: #000000;
  font-family: "Inter", sans-serif;
  font-size: 1em;
  height: 56px;
  outline: none;
  padding-left: 13px;
  padding-right: 13px;
  width: 100%;
}

.form-input-green {
  border: 1px solid #00c700;
}

.form-input-gray {
  border: 1px solid #676767;
}

.form-input-red {
  border: 1px solid #fe2d2d;
}

.form-input-otp {
  border: solid 0.9px #00c700;
  border-radius: 0;
  color: #000000;
  font-size: 21px;
  font-weight: 500;
  width: 100%;
  min-height: 65px;
  text-align: center;
}

.form-input-otp::placeholder {
  color: #bbbdc0;
}

/* Label */
.label-input {
  cursor: text;
  font-family: "Inter", sans-serif;
  font-size: 1em;
  pointer-events: none;
  position: absolute;
  left: 13px;
  top: 31%;
  transition: 0.8s ease-in-out all;
  -moz-transition: 0.8s ease-in-out all;
  -webkit-transition: 0.8s ease-in-out all;
}

input:focus + .label-input,
input:read-only + .label-input,
input:valid + .label-input {
  background-color: #ffffff;
  color: #676767;
  font-size: 0.94em;
  top: -10px;
}

@media (max-width: 400px) {
  .label-input {
    font-size: 0.75em;
    top: 35%;
  }
  input:focus + .label-input,
input:valid + .label-input {
    font-size: 0.75em;
  }
}
.form-label-green {
  color: #00c700;
}

.form-label-gray {
  color: #676767;
}

.form-label-red {
  color: #fe2d2d;
}

/* Popup */
.form-popup {
  background-color: #ffffff;
  border-radius: 10px;
  width: 368px;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 3000;
}

.close-button {
  cursor: pointer;
  position: absolute;
  top: 28px;
  right: 28px;
}

@media (max-width: 400px) {
  .form-popup {
    width: 95%;
  }
}
/* Container */
.container-border-green {
  border: 1px solid #009a48;
  border-radius: 16px;
  padding: 15px 15px 15px 15px;
}

.container-button {
  display: flex !important;
  align-items: center !important;
  flex-direction: row !important;
  justify-content: center !important;
  width: 85% !important;
}

.container-center-button {
  width: 50% !important;
}

.container-left-button {
  padding-right: 10px;
  width: 50% !important;
}

.content-message {
  border-radius: 15px;
  box-shadow: 0 8px 16px 0 #c0c9d2;
  background-color: #ffffff;
  padding-top: 48px;
  resize: both;
  animation: appear-down 0.5s;
  transition: 3s ease;
}

.container-right-button {
  padding-left: 10px;
  width: 50% !important;
}

.content-section-border {
  display: flex;
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.content-section-border-between {
  border-right: 1px solid #009a48;
  height: 70px;
}

.content-section-border-contain {
  border-left: 1px solid #009a48;
  border-right: 1px solid #009a48;
  border-bottom: 1px solid #009a48;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  display: flex;
  height: 80px;
}

.content-section-separator-contain {
  border-bottom: 1px solid #009a48;
  margin-left: 1px;
  margin-right: 1px;
  padding-bottom: 10px;
}

.content-step-bar {
  height: 90px;
  animation: appear-down 0.1s;
  transition: 3s ease;
}

@media (max-width: 576px) {
  .content-message {
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
    padding-top: 0;
  }
}
@media (max-width: 991px) {
  .container-button {
    flex-direction: column !important;
  }
  .container-center-button {
    width: 100% !important;
  }
  .container-left-button {
    padding-right: 0;
    padding-bottom: 16px;
    width: 100% !important;
  }
  .container-right-button {
    padding-left: 0;
    width: 100% !important;
  }
  .content-section-border {
    height: 150px;
  }
  .content-section-border-between {
    border: none;
    height: 75px;
  }
  .content-section-border-contain {
    display: block;
  }
  .content-section-separator-contain {
    border-bottom: none;
  }
}
/* Progress bar steps */
.progress-bar-step {
  padding-inline-start: 1px;
  width: 100% !important;
}

.progress-bar-step li {
  align-items: center;
  float: left;
  list-style-type: none;
  position: relative;
}

/* Animation */
.animation-button {
  position: relative;
  animation: appear-left 1.1s;
  transition-duration: 5s linear;
}

.animation-notification {
  position: relative;
  animation: appear-right 0.5s;
  transition-duration: 5s linear;
  z-index: 1;
}

.animation-description {
  position: relative;
  animation: appear-left 0.5s;
  transition-duration: 5s linear;
}

.animation-text-document {
  position: relative;
  animation: appear-left 0.7s;
  transition-duration: 5s linear;
}

.animation-text-offices {
  position: relative;
  animation: appear-left 0.9s;
  transition-duration: 5s linear;
}

.animation-title-bold {
  position: relative;
  animation: appear-left 0.3s;
  transition-duration: 5s linear;
}

.animation-title-stroke {
  position: relative;
  animation: appear-left 0.1s;
  transition-duration: 5s linear;
}

/* Icons */
.icon-table-blue {
  padding: 12px;
  background-color: #bbe0ff;
  border-radius: 30px;
  margin-top: 10px;
  margin-bottom: 25px;
}

/* Upload_document */
.list-document {
  border: 1px solid #f5f5f5;
  border-radius: 10px;
}

.list-document-upload {
  display: flex;
}

.list-document-upload:first-child,
.list-document-upload:last-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.list-document-name {
  max-width: 580px;
  width: 100%;
  font-size: 13px;
  font-weight: 600;
}

.list-document-name img {
  margin: 15px 5px 12px 7px;
}

.list-document-upload:nth-child(even) {
  background: #e4e4e4;
}

.upload {
  margin: 11px;
}

.text-capitalize {
  text-transform: capitalize;
}

@media all and (max-width: 1024px) {
  .list-document-upload {
    max-height: none;
  }
}
/* Document Upload Notification */
.close-orange {
  background: #fcdc7a;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  text-align: center;
  margin-top: 10px;
}

.icon-notification {
  text-align: center;
  height: 29px;
  border-radius: 15px;
  margin-top: 7px;
  margin-right: 15px;
  padding: 1px 7px 0px 5px;
}

.notification-header-upload {
  display: flex;
  padding: 20px;
}

.notification-content {
  max-width: 350px;
  margin-top: 6px;
  width: 100%;
  padding-left: 10px;
}

.notification-content-file {
  color: #9d9d9d;
  font-size: 11px;
  line-height: 1.82;
  letter-spacing: normal;
}

.notification-sucess {
  border-top: 1px solid #d9d9d9;
}

.notification-upload {
  border-top: 1px solid #d9d9d9;
  display: flex;
  padding: 15px 5px 0px 13px;
}

.notification-upload:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.notification-upload-container {
  animation: fadeIn ease 1s;
  max-width: 400px;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #e4e4e4;
  box-shadow: 6px 6px 8px -5px #c0c9d2;
  color: #000000;
  position: fixed;
  right: 20px;
  z-index: 2000;
}

.text-upload {
  color: #ffa025 !important;
}

.vertical-line {
  width: 4px;
  height: 40px;
  margin: 0 9px 0 0;
  border-radius: 2px;
}

.vertical-line-upload {
  background-color: #ffa025;
}

.vertical-line-success {
  background-color: #00c700;
}

.vertical-line-error {
  background-color: #fe2d2d;
}

/* Loading */
.text-white-bold {
  font-weight: bold;
  color: #ffffff;
}

.text-error-bold {
  font-weight: bold;
  color: #fe2d2d;
}

/* Scroll */
.justify-with-scroll {
  justify-content: center;
}

@media (max-width: 991px) {
  .justify-with-scroll {
    justify-content: flex-start;
    margin-top: 10%;
  }
}